<template>
    <div>
        <el-card>
            <template #header>
                <div style="text-align:left">
                    商户列表
                </div>
            </template>
            <div class="content" style="display: flex;align-items: center;">
                <span style="font-size:14px;margin-right:10px;">商户状态:</span>
                <el-select v-model="status" clearable style="margin-right:10px;width:90px;" size="small">
                    <el-option label="全部" value="1"></el-option>
                    <el-option label="启用" value="2"></el-option>
                    <el-option label="停用" value="3"></el-option>
                </el-select>
                <span style="font-size:14px;margin-right:10px;">商户类别:</span>
                <el-select v-model="merchanttype" clearable style="margin-right:10px;width:90px;" size="small">
                    <el-option label="全部" value="1"></el-option>
                    <el-option label="商户" value="2"></el-option>
                    <el-option label="代理商" value="3"></el-option>
                </el-select>
                <span style="font-size:14px;margin-right:10px;">店铺状态:</span>
                <el-select v-model="shopstatus" clearable style="margin-right:10px;width:90px;" size="small">
                    <el-option label="全部" value="1"></el-option>
                    <el-option label="正常" value="2"></el-option>
                    <el-option label="关店" value="3"></el-option>
                </el-select>
                <el-input v-model="keyword" placeholder="商户代码/商户名称" prefix-icon="el-icon-search" clearable
                    class="hidden-sm-and-down" style="width:200px" size="small"></el-input>
                <el-button type="primary" @click="init" style="margin-left:10px;" class="hidden-sm-and-down"
                    size="small">搜索</el-button>
                <el-button type="success" @click="Add" size="small">添加</el-button>
                <el-button type="success" @click="Addagent" size="small">添加代理商</el-button>
                <div style="position: relative;left:14%;">
                    <el-text>资商通：</el-text>
                    <el-button type="success" v-if="business == 0" @click="points(1)" round plain
                        size="small">开启</el-button>
                    <el-button type="danger" v-else round plain @click="points(0)" size="small">关闭</el-button>
                </div>
            </div>
            <div class="content-main">
                <el-table :data="tabledata" border style="width: 100%; margin: 1em 0" v-loading="load"
                    element-loading-spinner="el-icon-loading" element-loading-text="加载中..."
                    :default-sort="{ prop: 'AddDTime', order: 'descending' }" size="small">
                    <el-table-column label="商户编号" prop="MerchantCode"></el-table-column>
                    <el-table-column label="商户名称" prop="Merchant" min-width="100px"></el-table-column>
                    <el-table-column label="商户联系人" prop="MerchantName"></el-table-column>
                    <el-table-column label="商户联系电话" prop="MerchantMobile"></el-table-column>
                    <el-table-column label="商户状态" prop="Status">
                        <template #default='scope'>
                            <span v-if="scope.row.Status == 0" style="color:red">停用</span>
                            <span v-if="scope.row.Status == 1" style="color:#67c23a">启用</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="店铺状态" prop="ShopStatus">
                        <template #default='scope'>
                            <span v-if="scope.row.ShopStatus == 0" style="color:red">关店</span>
                            <span v-if="scope.row.ShopStatus == 1" style="color:#67c23a">正常</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否支持E签宝" prop="EsignEnable">
                        <template #default='scope'>
                            <span v-if="scope.row.EsignEnable == 0" style="color:red">否</span>
                            <span v-if="scope.row.EsignEnable == 1" style="color:#67c23a">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否支持风控" prop="RiskEnable">
                        <template #default='scope'>
                            <span v-if="scope.row.RiskEnable == 0" style="color:red">否</span>
                            <span v-if="scope.row.RiskEnable == 1" style="color:#67c23a">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否支持租赁宝上链" prop="ChainEnable">
                        <template #default='scope'>
                            <span v-if="scope.row.ChainEnable == 0" style="color:red">否</span>
                            <span v-if="scope.row.ChainEnable == 1" style="color:#67c23a">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否支持保险" prop="Insurance">
                        <template #default='scope'>
                            <span v-if="scope.row.Insurance == 0" style="color:red">否</span>
                            <span v-if="scope.row.Insurance == 1" style="color:#67c23a">是</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="是否开通腾讯电子签" prop="TencentEVisa">
                        <template #default="scope">
                            <el-switch v-model="scope.row.TencentEVisa" inactive-value="0" active-value="1"
                                @change="EVisaChange(scope.row.ID, scope.row.TencentEVisa, scope.$index)">
                            </el-switch>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="150" align="center">
                        <template #default='scope'>
                            <el-button size="small" @click="CreateEVisa(scope.row.ID, scope.$index)"
                                type="text">生成腾讯电子签链接</el-button>
                            <el-button size="small" v-if="scope.row.bind_bank == 0" @click="CreateLianLian(scope.row)"
                                type="text">开通连连个人账户</el-button>
                            <el-button size="small" v-if="scope.row.bind_bank == 0"
                                @click="CreateLianLianQiYe(scope.row)" type="text">开通连连企业账户</el-button>
                            <el-button size="small" v-if="scope.row.lianlian_type == 1"
                                @click="GeRenWithdraw(scope.row)" type="text">已开通连连个人账户</el-button>
                            <el-button size="small" v-if="scope.row.lianlian_type == 2" @click="QiYeWithdraw(scope.row)"
                                type="text">已开通连连企业账户</el-button>
                            <el-button size="small" v-if="scope.row.tx_type == 0" @click="LianLianMianYan(scope.row)"
                                type="text">签署连连免密提现协议</el-button>
                            <el-button size="small" v-if="scope.row.Status == 1"
                                @click="DisableMerchant(scope.row.ID, scope.$index)" type="text">停用</el-button>
                            <el-button size="small" v-if="scope.row.Status == 0"
                                @click="EnableMerchant(scope.row.ID, scope.$index)" type="text">启用</el-button>
                            <el-button size="small" @click="Edit(scope.row.ID)" type="text">编辑</el-button>
                            <el-button size="small" v-if="scope.row.Status == 0 && scope.row.ShopStatus == 1"
                                @click="DisableShop(scope.row.ID, scope.$index)" type="text">关店</el-button>
                            <el-button size="small" v-if="scope.row.Status == 0 && scope.row.ShopStatus == 0"
                                @click="EnableShop(scope.row.ID, scope.$index)" type="text">开店</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination @current-change="handleCurrentChange" @size-change="handleSizeChange"
                    :current-page="curpage" :page-size="pagesize" :page-sizes="[10, 20, 30, 50, 100]"
                    layout="sizes,total, prev, pager, next, jumper" :total="totalcount" :hide-on-single-page="false"
                    style="text-align:center"></el-pagination>
            </div>
        </el-card>
    </div>
    <el-dialog v-model="dialogVisible" title="提现" width="30%">
        <el-tabs v-model="activeName" class="demo-tabs" @tab-click="handleClick">
            <el-tab-pane label="提现" name="first">
                <el-form :model="form" label-width="auto" style="max-width: 600px">
                    <el-form-item label="账户余额：">
                        <el-input v-model="form.name" disabled />
                    </el-form-item>
                    <el-form-item label="提现金额">
                        <el-input v-model="form.amount" clearable placeholder="请输入提现金额" />
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">提现</el-button>
                    </el-form-item>
                </el-form>
            </el-tab-pane>
            <el-tab-pane label="提现记录" name="second">
                <el-table :data="tableData1" height="250" style="width: 100%">
                    <el-table-column prop="amount" label="提现金额" width="180" />
                    <el-table-column prop="time" label="提现时间" width="180" />
                </el-table>
            </el-tab-pane>
        </el-tabs>
        <template #footer>
            <div class="dialog-footer">
                <el-button @click="dialogVisible = false">关闭</el-button>
            </div>
        </template>
    </el-dialog>
</template>
<style>
.el-message-box__message {
    word-wrap: break-word;
}

.el-card__body {
    padding: 20px;
}
</style>
<script>
import { ElMessageBox, ElMessage } from 'element-plus'
import constant from "@/constant"
import { reactive, ref } from 'vue'
export default {
    data() {
        return {
            business: '',
            activeName: ref('first'),
            dialogVisible: ref(false),
            tabledata: [],
            tableData1: [],
            keyword: '',
            status: '',
            shopstatus: '',
            curpage: '1',
            pagesize: '10',
            totalcount: "",
            form: reactive({
                name: "",
                amount: ""
            }),
            merchanttype: "",
            lastTime: 0,
            throttleTime: 1000
        }
    },
    methods: {
        points(status) {
            const now = Date.now();
            if (now - this.lastTime >= this.throttleTime) {
                // 如果当前时间与上次执行时间之差大于节流时间，则执行发送逻辑  
                this.sendmsg(status);
                this.lastTime = now; // 更新上次执行时间  
            }
        },
        sendmsg(status) {
            this.axios.post(constant.points, { status: status }).then((response) => {
                console.log(response)
                this.business = response.data.status
            })
            if (this.business !== '') {
                this.$message.success('操作成功');
            }
        },
        LianLianMianYan(row) {
            this.axios.get(constant.LianLianMianYan, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: row.ID
                }
            }).then((response) => {
                console.log(response.data)
                if (response.data.ret_code == '0000') {
                    ElMessageBox.confirm(
                        `${response.data.gateway_url}`,
                        '连连账户链接',
                        {
                            confirmButtonText: '复制',
                            cancelButtonText: '关闭',
                            type: 'warning',
                        }
                    ).then(() => {
                        var textarea = document.createElement("textarea"); //创建临时的textarea元素
                        textarea.value = response.data.gateway_url; //将需要复制的文本赋值给textarea
                        document.body.appendChild(textarea); //添加到页面上
                        textarea.select(); //选中textarea里的文本
                        try {
                            var successful = document.execCommand('copy'); //执行复制命令
                            var msg = successful ? '成功' : '失败';
                            console.log('已复制', msg);
                            ElMessage({
                                type: 'success',
                                message: '复制' + msg,
                            })
                        } catch (err) {
                            console.error('无法复制', err);
                            ElMessage({
                                type: 'error',
                                message: '无法复制',
                            })
                        } finally {
                            document.body.removeChild(textarea); //移除临时的textarea元素
                        }
                    })
                }
            })
        },
        onSubmit() {
            console.log(this.form)
            if (this.form.amount == '') {
                this.$message.error('请输入提现金额')
                return false
            }
            this.axios.get(constant.tixian, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: this.form
            }).then((response) => {
                console.log(response.data)
                if (response.data.ret_code == '0000') {
                    this.$message.success(`提现成功${this.form.amount}元`)
                }
            })
        },
        handleClick(tab) {
            console.log(tab.props.name)
            if (tab.props.name == 'second') {
                this.axios.get(constant.lianlianjl, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: this.form
                }).then((response) => {
                    console.log(response.data)
                    this.tableData1 = response.data
                })
            }
        },
        QiYeWithdraw(row) {
            console.log(row)
            this.form.id = row.ID
            this.dialogVisible = true
            this.axios.get(constant.chaxunyue, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: row.ID
                }
            }).then((response) => {
                console.log(response.data)
                this.form.name = response.data
            })
        },
        CreateLianLianQiYe(row) {
            console.log(row)
            this.axios.get(constant.lianlianQiYe, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: row.ID
                }
            }).then((response) => {
                console.log(response)
                if (response.data.ret_code == '0000') {
                    ElMessageBox.confirm(
                        `${response.data.gateway_url}`,
                        '连连账户链接',
                        {
                            confirmButtonText: '复制',
                            cancelButtonText: '关闭',
                            type: 'warning',
                        }
                    ).then(() => {
                        var textarea = document.createElement("textarea"); //创建临时的textarea元素
                        textarea.value = response.data.gateway_url; //将需要复制的文本赋值给textarea
                        document.body.appendChild(textarea); //添加到页面上
                        textarea.select(); //选中textarea里的文本
                        try {
                            var successful = document.execCommand('copy'); //执行复制命令
                            var msg = successful ? '成功' : '失败';
                            console.log('已复制', msg);
                            ElMessage({
                                type: 'success',
                                message: '复制' + msg,
                            })
                        } catch (err) {
                            console.error('无法复制', err);
                            ElMessage({
                                type: 'error',
                                message: '无法复制',
                            })
                        } finally {
                            document.body.removeChild(textarea); //移除临时的textarea元素
                        }
                    })
                }
            })
        },
        CreateLianLian(row) {
            console.log(row)
            this.axios.get(constant.lianlian, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: row.ID
                }
            }).then((response) => {
                console.log(response)
                if (response.data.ret_code == '0000') {
                    ElMessageBox.confirm(
                        `${response.data.gateway_url}`,
                        '连连账户链接',
                        {
                            confirmButtonText: '复制',
                            cancelButtonText: '关闭',
                            type: 'warning',
                        }
                    ).then(() => {
                        var textarea = document.createElement("textarea"); //创建临时的textarea元素
                        textarea.value = response.data.gateway_url; //将需要复制的文本赋值给textarea
                        document.body.appendChild(textarea); //添加到页面上
                        textarea.select(); //选中textarea里的文本
                        try {
                            var successful = document.execCommand('copy'); //执行复制命令
                            var msg = successful ? '成功' : '失败';
                            console.log('已复制', msg);
                            ElMessage({
                                type: 'success',
                                message: '复制' + msg,
                            })
                        } catch (err) {
                            console.error('无法复制', err);
                            ElMessage({
                                type: 'error',
                                message: '无法复制',
                            })
                        } finally {
                            document.body.removeChild(textarea); //移除临时的textarea元素
                        }
                    })
                }
            })
        },
        CreateEVisa(id, index) {
            console.log(id, index)
            this.axios.get(constant.CreateConsoleLoginUrl, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: id
                }
            }).then((response) => {
                console.log(response.data);
                ElMessageBox.confirm(
                    `${response.data.ConsoleUrl}`,
                    '腾讯电子签链接',
                    {
                        confirmButtonText: '打开',
                        cancelButtonText: '关闭',
                        type: 'warning',
                    }
                ).then(() => {
                    window.open(response.data.ConsoleUrl, '_blank');
                    // var textarea = document.createElement("textarea"); //创建临时的textarea元素
                    // textarea.value = response.data.ConsoleUrl; //将需要复制的文本赋值给textarea
                    // document.body.appendChild(textarea); //添加到页面上
                    // textarea.select(); //选中textarea里的文本
                    // try {
                    //     var successful = document.execCommand('copy'); //执行复制命令
                    //     var msg = successful ? '成功' : '失败';
                    //     console.log('已复制', msg);
                    //     ElMessage({
                    //         type: 'success',
                    //         message: '复制'+msg,
                    //     })
                    // } catch (err) {
                    //     console.error('无法复制', err);
                    //     ElMessage({
                    //         type: 'error',
                    //         message: '无法复制',
                    //     })
                    // } finally {
                    //     document.body.removeChild(textarea); //移除临时的textarea元素
                    // }

                })
            })
        },
        EVisaChange(id, evisa, index) {
            console.log(id, evisa, index)
            this.axios.get(constant.esign, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    id: id,
                    evisa: evisa
                }
            }).then((response) => {
                console.log(response.data);
                if (response.data.code == "200") {
                    this.$message.success('操作成功');
                    this.tabledata[index].TencentEVisa = evisa;
                } else {
                    this.tabledata[index].TencentEVisa = 0;
                }
            });
        },
        Add() {
            this.$router.push({ path: '/addmerchant' });
        },
        Addagent() {
            this.$router.push({ path: '/addagent' });
        },
        Edit(id) {
            this.$router.push({ path: '/addmerchant', query: { id: id } });
        },
        DisableMerchant(id, index) {
            this.$confirm("确定要停用这个商户么？", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.axios.get(constant.disablemerchant, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        id: id
                    }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success('操作成功');
                        this.tabledata[index].Status = 0;
                    }
                });
            }).catch(() => {
                return false;
            })
        },
        EnableMerchant(id, index) {
            this.$confirm("确定要启用这个商户么？", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.axios.get(constant.enablemerchant, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        id: id
                    }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success('操作成功');
                        this.tabledata[index].Status = 1;
                        this.tabledata[index].ShopStatus = 1;
                    }
                });
            }).catch(() => {
                return false;
            })
        },
        DisableShop(id, index) {
            this.$confirm("确定要关闭该商户的店铺么？", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.axios.get(constant.disableshop, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        id: id
                    }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success('操作成功');
                        this.tabledata[index].ShopStatus = 0;
                    }
                });
            }).catch(() => {
                return false;
            })
        },
        EnableShop(id, index) {
            this.$confirm("确定要开启该商户的店铺么？", '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning',
            }).then(() => {
                this.axios.get(constant.enableshop, {
                    headers: {
                        "Content-Type": "application/json"
                    },
                    params: {
                        id: id
                    }
                }).then((response) => {
                    console.log(response.data);
                    if (response.data == "OK") {
                        this.$message.success('操作成功');
                        this.tabledata[index].ShopStatus = 1;
                    }
                });
            }).catch(() => {
                return false;
            })
        },
        handleCurrentChange(val) {
            console.log(val);
            this.curpage = val;
            this.init();
        },
        handleSizeChange(val) {
            console.log(val);
            this.pagesize = val
            this.init();
        },
        init() {
            this.axios.get(constant.merchantlist, {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    curpage: this.curpage,
                    pagesize: this.pagesize,
                    keyword: this.keyword,
                    status: this.status,
                    shopstatus: this.shopstatus,
                    merchanttype: this.merchanttype
                }
            }).then((response) => {
                console.log(response.data);
                this.tabledata = response.data.list;
                this.curpage = response.data.curpage * 1;
                this.totalcount = response.data.totalcount * 1;
            });
        }
    },
    created: function () {
        this.init();
        this.sendmsg(this.business)
    }
}
</script>